(function (a) 
{ 
    a.fn.modalAnimate = function (b) 
    { 
        var d = a(this), 
            e = a.extend({ modalTarget: 'modal-container', effect: 'unfolding', autoEffect: !1, modalClose: function () { } },  b), 
            f = a('#' + e.modalTarget); 

        var cl = $('#btnClose'); 

        if (!0 === e.autoEffect) 
        { 
            var g = a(d).attr('data-effect'); f.removeAttr('class').addClass(g), a('body').addClass('modal-active') 
        } 
        else 
        d.click(function (h) 
        {
             h.preventDefault(); var i = a(d).attr('data-effect'); f.removeAttr('class').addClass(i), a('body').addClass('modal-active') 
        }); 
        
        cl.click(function (h) 
        { 
            h.preventDefault(), a('#modal-container').addClass('out'), a('body').removeClass('modal-active'), $('#vidCont').addClass('opacar'), $("#daIntro").addClass('opacar2'),
            
            //mostrando el navbar de dat-training cuando cerramos POPPUP

            nav_dat_training = document.getElementById("navbar-dat-training");

            nav_main = document.getElementById("navbarCanvas-main");

            if (nav_dat_training !=null && nav_main == null)
            {
                document.getElementById("navbar-dat-training").style.display="block";
            }

            if (nav_main !=null && nav_dat_training == null)
            {
                document.getElementById("navbarCanvas-main").style.display="block";
            }
            
        }) 
    } 
})(jQuery);
